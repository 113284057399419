export default defineNuxtRouteMiddleware(async (to, _from) => {
  const userStore = useUserStore();

  // Fetch user data to ensure the state is up-to-date
  await userStore.fetchUser();

  // If the user is not logged in
  if (!userStore.loggedIn) {
    // Handle the special case for logout redirection
    if (to.path === '/redirect/logout') {
      return navigateTo('/');
    }

    // Avoid infinite redirect by checking if the current path already includes the redirect query
    if (to.query.redirect !== to.path) {
      return navigateTo(`/?redirect=${to.path}`);
    }
  }
});
